@width-jp-icon:20px;
@width-jp-time:40px;
@width-jp-icon-margin:10px;
@outerWidth-jp-icon:@width-jp-icon + @width-jp-icon-margin;
@width-jp-progress-slider:240px;
@width-jp-volume-slider:100px;
@width-jp-gui:@outerWidth-jp-icon*6+@width-jp-icon-margin*3+@width-jp-progress-slider+@width-jp-time*2+@width-jp-volume-slider;
.jp-gui {
  position:relative;
  width:@width-jp-gui;
  &.jp-no-stop,
  &.jp-no-upload,
  &.jp-no-repeat{
    &{
      width:@width-jp-gui - @outerWidth-jp-icon;
    }
    .jp-volume-slider{
      left:@outerWidth-jp-icon*4+@width-jp-icon-margin*2+@width-jp-progress-slider+@width-jp-time*2;
    }
  }
  &.jp-no-stop {
    &.jp-no-repeat,
    &.jp-no-upload {
      width:@width-jp-gui - @outerWidth-jp-icon*2;
    }
    .jp-stop{
      display:none !important;
    }
    .jp-progress-slider{
      left:@outerWidth-jp-icon+@width-jp-icon-margin;
    }
  }
  &.jp-no-repeat {
    .jp-repeat,
    .jp-repeat-off {
      display:none !important;
    }
    &.jp-no-upload {
      width:@width-jp-gui - @outerWidth-jp-icon*2;
    }
    &.jp-no-stop {
      &.jp-no-upload {
        width:@width-jp-gui - @outerWidth-jp-icon*3;
      }
      .jp-volume-slider{
        left:@outerWidth-jp-icon*3+@width-jp-icon-margin*2+@width-jp-progress-slider+@width-jp-time*2;
      }
    }
  }
  &.jp-no-upload {
    .jp-volume-slider{
      left:@outerWidth-jp-icon*5+@width-jp-icon-margin*2+@width-jp-progress-slider+@width-jp-time*2;
    }
    .jp-upload {
      display:none !important;
    }
  }
  &.jp-no-volume {
    &{
      width:@width-jp-gui - @width-jp-volume-slider;
      .jp-volume-slider {
        display:none;
      }
    }
    &.jp-no-repeat,
    &.jp-no-upload,
    &.jp-no-stop {
      width:@width-jp-gui - (@width-jp-volume-slider + @outerWidth-jp-icon);
    }
    &.jp-no-stop {
      &.jp-no-repeat,
      &.jp-no-upload {
        width:@width-jp-gui - (@width-jp-volume-slider + @outerWidth-jp-icon*2);
      }
    }
    &.jp-no-repeat{
      &.jp-no-upload {
        width:@width-jp-gui - (@width-jp-volume-slider + @outerWidth-jp-icon*2);
      }
      &.jp-no-stop {
        &.jp-no-upload {
          width:@width-jp-gui - @outerWidth-jp-icon*3;
        }
      }
    }
    .jp-upload {
      margin-left:@width-jp-icon-margin/2;
    }
  }

  ul {
    margin:0;
    padding:0;
  }
  ul li {
    position:relative;
    float: left;
    list-style:none;
    cursor:pointer;
    width: @width-jp-icon;
    margin-right: @width-jp-icon-margin;
    text-align: center;
  }
  ul li a {
    outline: none;
  }
  li.jp-current-time,
  li.jp-duration{
    width: @width-jp-time;
    margin: 0;
  }
  li.jp-current-time {
    margin-left:@width-jp-progress-slider+@width-jp-icon-margin*2;
  }
  li.jp-current-time-divider {
    margin:0;
  }
  li.jp-repeat,
  li.jp-repeat-off {
    margin-left:@width-jp-icon-margin;
    margin-right: 0;
  }
  li.jp-repeat-off {
    opacity: 0.6;
    filter: ~"alpha(opacity=0.6)";
  }
  li.jp-mute,
  li.jp-unmute {
    margin-left:@width-jp-icon-margin;
  }
  li.jp-upload {
    margin-left:@width-jp-icon-margin/2+@width-jp-volume-slider;
  }

  .jp-volume-slider,
  .jp-progress-slider {
    position:absolute;
    top:3px;
    left:@outerWidth-jp-icon*2+@width-jp-icon-margin;
    width:@width-jp-progress-slider;
    height: 2px;
    border: 0;
    border-radius: 0;
    background: none;
    background-color: #e4e4e4;
    margin-top: 7px;
  }
  .jp-volume-slider .ui-slider-handle,
  .jp-progress-slider .ui-slider-handle {
    cursor:pointer;
    width: 10px;
    height: 10px;
    border: 2px solid #3b3b3b;
    border-radius: 10px;
    background: none;
    background-color: #ffffff;
    outline: none;
  }
  .jp-volume-slider .ui-widget-header,
  .jp-progress-slider .ui-widget-header {
    background: none;
    background-color: #2678b1;
  }
  .jp-volume-slider {
    position:absolute;
    top:5px;
    left:@outerWidth-jp-icon*5+@width-jp-icon-margin*2+@width-jp-progress-slider+@width-jp-time*2;
    width:@width-jp-volume-slider;
    margin-top: 5px;
  }
  
}

li.jp-pause,
li.jp-repeat-off,
li.jp-unmute,
.jp-no-solution {
  display:none;
}