@font-face {
  font-family: 'BlissPro-Regular';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/BlissPro-Regular.eot');
  src: url('../fonts/BlissPro-Regular.eot?#iefix') format('embedded-opentype');
  src: local('BlissPro-Regular'),
    url('../fonts/BlissPro-Regular.woff') format('woff'),
    url('../fonts/BlissPro-Regular.ttf') format('truetype'),
    url('../fonts/BlissPro-Regular.svg#BlissPro-Regular') format('svg');
}
@font-face {
  font-family: 'BlissPro-Light';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/BlissPro-Light.eot');
  src: url('../fonts/BlissPro-Light.eot?#iefix') format('embedded-opentype');
  src: local('BlissPro-Light'),
    url('../fonts/BlissPro-Light.woff') format('woff'),
    url('../fonts/BlissPro-Light.ttf') format('truetype'),
    url('../fonts/BlissPro-Light.svg#BlissPro-Light') format('svg');
}
@font-face {
  font-family: 'BlissPro-Bold';
  src: url('../fonts/BlissPro-Bold.eot');
  src: url('../fonts/BlissPro-Bold.eot?#iefix') format('embedded-opentype');
  src: local('BlissPro-Bold'),
    url('../fonts/BlissPro-Bold.woff') format('woff'),
    url('../fonts/BlissPro-Bold.ttf') format('truetype'),
    url('../fonts/BlissPro-Bold.svg#BlissPro-Bold') format('svg');
}
@font-face {
  font-family: 'BlissPro-Extrabold';
  src: url('../fonts/BlissPro-Extrabold.eot');
  src: url('../fonts/BlissPro-Extrabold.eot?#iefix') format('embedded-opentype');
  src: local('BlissPro-Extrabold'),
    url('../fonts/BlissPro-Extrabold.woff') format('woff'),
    url('../fonts/BlissPro-Extrabold.ttf') format('truetype'),
    url('../fonts/BlissPro-Extrabold.svg#BlissPro-Extrabold') format('svg');
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto.eot');
  src: url('../fonts/Roboto.eot?#iefix') format('embedded-opentype');
  src: local('Roboto'),
    url('../fonts/Roboto.woff') format('woff'),
    url('../fonts/Roboto.ttf') format('truetype'),
    url('../fonts/Roboto.svg#Roboto') format('svg');
}