
.terminal-table {
  .del-terminal {
    padding-top: 9px;
    display: inline-block;
  }
}

.alert-error {
  background-color: rgba(253,200,200,1);
  border-color: red;
}
.alert-error:before {
  content: "Ошибка: ";
}
.black {
  color: #3b3b3b !important;
}

.background-fade-in {
  opacity: 0.5;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.leaf-block-fade {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  border-radius: 5px;
}
#select_block_description {
  position: absolute;
  top: 0px;
  right: 0px;
}
.leaf-type-no-function {
  background-color: #e4e4e4 !important;
  padding: 10px 50px !important;;
    > span {
      color: #8f8f8f !important;
      font-size: 10px !important;
      text-transform: none !important;
    }
}

.m-h-60 {
  min-height: 60px;
}
.m-h-120 {
  min-height: 120px;
}
.w-s-n {
  white-space: normal;
}
.m-l-360-i {
  margin-left: 360px !important;
}
.w-340-i {
  width: 340px !important;
}
.w-570-i {
  width: 570px !important;
}
.w-600 {
  width: 600px;
}
.w-800 {
  width: 800px;
}
.glyphicon-remove-circle.small {
  font-size: 14px;
}

//Каледарь
.ui-widget-header {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    background: #00BB9B;
  color: white;
  font-weight: normal;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #00BB9B;
  background: #00BB9B;
  color: white;
}

//Кнопка "Напишите нам"
.lt-label {
  z-index: 1060                                                             ;
  position: fixed;
  background-color: #2678b1;
  -webkit-transition: bottom .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -moz-transition: bottom .4s ease-in-out, -moz-transform .4s ease-in-out;
  -ms-transition: bottom .4s ease-in-out, -ms-transform .4s ease-in-out;
  -o-transition: bottom .4s ease-in-out, -o-transform .4s ease-in-out;
  transition: bottom .4s ease-in-out, transform .4s ease-in-out;
  right: 50px;
  bottom: 1px;
  -webkit-border-radius: 25px 25px 0 0;
  -moz-border-radius: 25px 25px 0 0;
  border-radius: 15px 15px 0 0;
  width: 148px;
  cursor: pointer;
  height: 26px;
}
.lt-label:hover {
  height: 27px;
}
.lt-label:before {
  right: auto;
  left: -20px;
  bottom: -6px;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.lt-label:before {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.o…E%3Cuse%20xlink%3Ahref%3D%22%23b%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}
.lt-label:before, .lt-label:after {
  color: #1b60a6;
}
.lt-label:before, .lt-label:after {
  content: '';
  bottom: -6px;
  height: 40px;
  width: 42px;
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
}
.lt-label .lt-chat-label {
  padding-left: 32px;
  position: absolute;
  top: 3px;
}
div.lt-label.lt-label-event.lt-offline > div > span {
  color: white;
  font-size: 13px;
}
div.lt-label.lt-label-event.lt-offline > div:nth-child(1) {
  left: -5px;
  background-color: #0f947d;
  top: -10px;
  border-radius: 25px;
  border: #2678b1 solid;
  position: absolute;
  width: 30px;
  height: 30px;
}
div.lt-label.lt-label-event.lt-offline > div:nth-child(1) > span {
  left: 5px;
  top: 4px;
}
.popover_help > button.close {
  position: absolute;
  right: -3px;
  top: -1px;
}
button.gen-pass {
  margin-top: 25px;
}
//body {
//  overflow-y: scroll !important;
//}
.no-pay-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
.popover-content{
  text-align: justify;
}

ul.ui-menu { max-height: 200px !important; }

body.modal-open{
  overflow: hidden;
}