@import "www/vo/less/fonts.less";
@import "www/vo/less/bootstrap-less/variables.less";

//== Основные цвета
//Основные цвета элементов Bootstrap
@brand-primary:  #4188b9;
@brand-success:  #19bb9a;
@brand-info:     #5bc0de;
@brand-warning:  #fdce58;
@brand-danger:   #ec454d;
@gray-base:     #3b3b3b;

//Цвет текста по умолчанию
@text-color:            #3b3b3b;
@headings-small-color:  inherit;
//Шрифт по умолчанию
@font-family-base: 'Roboto', Arial, sans-serif;

//Используются для статусов текста, элементов форм, для компонента alert
@state-success-text:    #19bb9a;
@state-success-bg:      lighten(@state-success-text, 54%);
@state-success-border:  @state-success-bg;

@state-info-text:       #4188b9;
@state-info-bg:         #f7fcff;
@state-info-border:     @state-info-bg;

@state-warning-text:    @brand-warning;
@state-warning-bg:      #fef5de;
@state-warning-border:  @state-warning-bg;

@state-warning-bright-text:    @text-color;
@state-warning-bright-bg:      #fdce58;
@state-warning-bright-border:  @state-warning-bg;

@state-danger-text:     #ec454d;
@state-danger-bg:       lighten(@state-danger-text, 35%);
@state-danger-border:   @state-danger-bg;

//Цвет ссылок по умолчанию
@list-group-link-color:         rgb(38, 120, 177);
@list-group-link-hover-color:   @list-group-link-color;
@list-group-link-heading-color: #333;

//Цвет фона для body
@body-bg:                   #efefef;

//== Wells
@well-bg:                   #ffffff;
@well-border:               darken(@well-bg, 7%);

//Ширина основного контейнера
@wrapper-width: 	   1200px;

//Ширина меню Свернутое/Развернутое состояние
@menu-sm-width:        63px;
@menu-lg-width:        260px;

//Скругления углов по умолчанию 
@border-radius-base:   6px;

//== Alerts
//отступы в блоках .alerts
@alert-padding:        12px;

@alert-warning-text:   @text-color;

@alert-success-text:   @text-color;

@alert-info-text:      @text-color;

@alert-danger-text:    @text-color;

//== Buttons
@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             @btn-primary-bg;
@btn-primary-box-shadow-color:   darken(@btn-primary-bg, 10%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             @btn-success-bg;
@btn-success-box-shadow-color:   #0f947d;

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                @btn-info-bg;
@btn-info-box-shadow-color:      darken(@btn-info-bg, 10%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             @btn-warning-bg;
@btn-warning-box-shadow-color:   darken(@btn-warning-bg, 10%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              @btn-danger-bg;
@btn-danger-box-shadow-color:    #b5323c;

//Размер шрифта у заголовков
@font-size-h1:            48px;
@font-size-h2:            36px;
@font-size-h3:            24px;
@font-size-h4:            20px;
@font-size-h5:            18px;
@font-size-h6:            16px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
@table-cell-padding:            12px;
//** Padding for cells in `.table-condensed`.
@table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
@table-bg:                      transparent;
//** Background color used for `.table-striped`.
@table-bg-accent:               #f9f9f9;
//** Background color used for `.table-hover`.
@table-bg-hover:                #d5e4ef;
@table-bg-active:               @table-bg-hover;

//** Border color for table and cell borders.
@table-border-color:            #ddd;

//== Forms

//** Text color for `<input>`s
@input-color:                    @text-color;
//** `<input>` border color
@input-border:                   #efefef;

@input-border-radius:            5px;

//** Default `.form-control` height
@input-height-base:              (@line-height-computed + (@padding-base-vertical * 3) + 2);
//** Large `.form-control` height
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

//== Modals
//
//##

@modal-header-border-color:   transparent;

@modal-lg:                    900px;
@modal-md:                    500px;
@modal-sm:                    230px;

//== Close
//
//##

@close-color:                 #2d2d2d;


//== Tabs
@nav-tabs-border-color:                     @body-bg;

@nav-tabs-link-hover-border-color:          @body-bg;

@nav-tabs-active-link-hover-bg:             #ffffff;
@nav-tabs-active-link-hover-color:          @text-color;
@nav-tabs-active-link-hover-border-color:   #ffffff;

@nav-tabs-justified-link-border-color:            #ffffff;
@nav-tabs-justified-active-link-border-color:     #ffffff;


//Список сценариев
@list-group-draggable-item: #e9f1f7;
@list-group-draggable-item-hover: #c3e4fd;
@list-group-draggable-item-padding: 10px 0px;
@list-group-draggable-item-margin: 0 0 20px 0;