@import "www/vo/less/core.less";

html,
body{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body{
  font: 400 @font-size-base/@line-height-base @font-family-base;
  background-color: @body-bg;
}

h1,h2,h4,h6,
.h1,.h2,.h4{
  font-family:'BlissPro-Extrabold', @font-family-base;
}
h3,.h3{
  font-size: @font-size-h3;
  font-family:'BlissPro-Light', @font-family-base;
  &.h3-bold{
    font-family:'BlissPro-Regular', @font-family-base;
  }
}
h5,.h5{
  &.h5-bold{
    font-family:'BlissPro-Extrabold', @font-family-base;
  }
}

a{
  &,
  &:hover,
  &:focus{
    text-decoration: none;
  }
}
a[class^='h']{
  color: @list-group-link-color;
}
.link_dashed,
.link_dashed:hover,
.link_dashed:focus{
  text-decoration: none;
  border-bottom: 1px dashed @list-group-link-color;
}
.link_dotted,
.link_dotted:hover,
.link_dotted:focus{
  text-decoration: none;
  border-bottom: 1px dotted @list-group-link-color;
}
a.glyphicon{
  &:hover,
  &:focus{
    text-decoration: none;
  }
}

.well{
  .box-shadow(0 2px 1px #dedede);
  margin-bottom: 20px;
  hr{
    border-top:2px dotted #dfdfdf;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.table{
  margin-left: -10px;
  >tbody>tr>td{
    vertical-align: middle;
  }
  >thead>tr>th{
    vertical-align: top;
  }
  tr{
    >th{
      small{
        font-weight: normal;
      }
    }
    > td{
      // width: 30%;
      &:last-child{
        .buttons{
          .opacity(0);
          text-align: right;
          .text-overflow();
          > a{
            .text-overflow();
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            &:hover,
            &:focus{
              text-decoration: none;
              .glyphicon-remove-circle{
                .fa-icon();
                font-size: 20px;
                line-height: 20px;
                margin-left: 1px;
                &:before{
                  content:"\f057";
                }
              }
            }
            > span,i{
              display: inline-block;
              vertical-align: middle;
            }
            .glyphicon{
              font-size: 18px;
              line-height: 19px;
            }
            .fa{
              font-size: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }
    &:hover{
      > td{
        .buttons{
          .opacity(1);
        }
      }
    }
  }
  //скругленные углы у строк
  &.table-hover{
    tr{
      >td,th{
        &:first-child{
          text-align: left;
          border-radius: @border-radius-base 0 0 @border-radius-base;
        }
        &:last-child{
          border-radius: 0 @border-radius-base @border-radius-base 0;
        }
      }
      .container-hover{
        display: none;
      }
      &:hover{
        .container-nohover{
          display: none;
        }
        .container-hover{
          display: table-cell;
        }
      }
    }
  }
  &.table-no-bordered{
    >tbody>tr>td, >tbody>tr>th{
      border: 0;
    }
    >thead>tr>th{
      border: 0;
    }
  }
  &.table_tariffs{
    position: relative;

    // table-layout:fixed;
    td,caption{
      padding-left: 15px;
    }
    tr{
      > td {
        text-align: right;
        width: 150px;
        height: 66px;
        &:first-child{
          width: 385px;
        }
      }
      &.caption{
        > td {
          padding-bottom: 20px;
        }
        &:hover{
          background: none;
        }
      }
    }
    //ширина ячеек если тарифов 4
    &.table_tariffs_4{
      tr{
        > td {
          &:first-child{
            width: 260px;
          }
        }
      }
    }
    tbody .popover {
      left: 0 !important;
    }
  }
}

#wrapper{
  width: @wrapper-width;
  min-height: 100%;
  #container{
    margin-left: @menu-lg-width;
    padding: 0 0 60px 60px;
    width: @wrapper-width - @menu-lg-width;
    .container_header{
      padding: 25px 0;
    }
    .container_content_title{
      margin-bottom: 15px;
      .link_back{
        display: block;
        &:before{
          content: " ";
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          position: relative;
          z-index: 2;
          background: transparent url('../images/sprite_icons.png') -80px 0 no-repeat;
        }
        &:hover,
        &:focus{
          color: @list-group-link-color;;
        }
        > span{
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  &.sidebar_submenu_active{
    #container{
      margin-left: @menu-sm-width+@menu-lg-width;
      width: @wrapper-width -  @menu-sm-width - @menu-lg-width;
    }
  }
}
#logo-insys{
  display: block;
  margin: 45px auto 0 auto;
  width: 90px;
  height: 23px;
  background:transparent url(../images/logo.png);
}
#logo-insys-big,
#logo-garant-asbest-big,
#logo-garant-big {
  display: block;
  margin: 65px auto 10px auto;
  width: 157px;
  height: 41px;
  background: transparent url(../images/logo_big.png);
}
#logo-garant-asbest-big,
#logo-garant-big {
  width: 208px;
  height: 67px;
}
#logo-garant-big {
  background: transparent url('../images/operators/logo_garant_big.png');
}

#logo-garant-asbest-big {
  background: transparent url('../images/operators/logo_garant_asbest_big.png') no-repeat center;
}

//иконки
.icon:before{
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.icon-ok:before{
  background: transparent url('../images/sprite_icons.png') 0 0 no-repeat;
}
.icon-up:before{
  background: transparent url('../images/sprite_icons.png') -20px 0 no-repeat;
}
.icon-down:before{
  background: transparent url('../images/sprite_icons.png') -40px 0 no-repeat;
}
.icon-download:before{
  background: transparent url('../images/sprite_icons.png') -60px 0 no-repeat;
}
.icon-retweet:before{
  background: transparent url('../images/sprite_icons.png') 0px -20px no-repeat;
}
.icon-clock:before{
  background: transparent url('../images/sprite_icons.png') -80px -20px no-repeat;
}
.icon-copy:before{
  background: transparent url('../images/sprite_icons.png') -60px -20px no-repeat;
}
.icon-calendar:before{
  background: transparent url('../images/sprite_icons.png') 0px -40px no-repeat;
}
.icon-arrow-left:before{
  background: transparent url('../images/sprite_icons.png') -20px -40px no-repeat;
}
.icon-arrow-right:before{
  background: transparent url('../images/sprite_icons.png') -40px -40px no-repeat;
}
.icon-refresh:before{
  background: transparent url('../images/sprite_icons.png') -60px -40px no-repeat;
}
.icon-search:before{
  background: transparent url('../images/sprite_icons.png') -80px -40px no-repeat;
}
.icon-play:before{
  background: transparent url('../images/sprite_icons.png') -60px -60px no-repeat;
}
.icon-play-blue:before{
  background: transparent url('../images/sprite_icons.png') -100px -21px no-repeat;
}
.icon-pause:before{
  background: transparent url('../images/sprite_icons.png') -80px -60px no-repeat;
}
.icon-stop:before{
  background: transparent url('../images/sprite_icons.png') -60px -80px no-repeat;
}
.icon-remove-circle:before{
  background: transparent url('../images/sprite_icons.png') 0px -60px no-repeat;
}
.icon-remove-circle-black:before{
  background: transparent url('../images/sprite_icons.png') 0px -80px no-repeat;
}
.icon-remove-circle:hover:before,
a:hover .icon-remove-circle:before{
  background: transparent url('../images/sprite_icons.png') -20px -60px no-repeat;
}
.icon-pencil:before{
  background: transparent url('../images/sprite_icons.png') -40px -60px no-repeat;
}
.icon-volume:before{
  width: 23px;
  background: transparent url('../images/sprite_icons.png') -20px -80px no-repeat;
}
.icon-volume-off:before{
  background: transparent url('../images/sprite_icons.png') -43px -80px no-repeat;
}
.icon-upload:before{
  background: transparent url('../images/sprite_icons.png') -80px -80px no-repeat;
}

//иконки для пользователя
.icon-user-sm:before{
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 40px;
  background: transparent url('../images/user_sm.png') 0 0 no-repeat;
}
.icon-user-lg:before{
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 44px;
  height: 62px;
  background: transparent url('../images/user.png') 0 0 no-repeat;
}

//иконки меню
.icon-menu:before{
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 31px;
  position: relative;
  z-index: 2;
}
.icon-menu-users:before{
  background: transparent url('../images/sprite_menu.png') 0 0 no-repeat;
}
.icon-menu-const:before{
  background: transparent url('../images/sprite_menu.png') -30px 0 no-repeat;
}
.icon-menu-nums:before{
  background: transparent url('../images/sprite_menu.png') -60px 0 no-repeat;
}
.icon-menu-phone:before{
  background: transparent url('../images/sprite_menu.png') -180px -62px no-repeat;
}
.icon-menu-fax:before{
  background: transparent url('../images/sprite_menu.png') -90px 0 no-repeat;
}
.icon-menu-stat:before{
  background: transparent url('../images/sprite_menu.png') -120px 0 no-repeat;
}
.icon-menu-lk:before{
  background: transparent url('../images/sprite_menu.png') -150px 0 no-repeat;
}
.icon-menu-right:before{
  background: transparent url('../images/sprite_menu.png') -180px -31px no-repeat;
}
.icon-menu-volume:before{
  background: transparent url('../images/sprite_menu.png') -210px 0 no-repeat;
}
.icon-menu-key:before{
  background: transparent url('../images/sprite_menu.png') -270px 0 no-repeat;
}
.icon-menu-end:before{
  background: transparent url('../images/sprite_menu.png') -300px 0 no-repeat;
}
.icon-menu-th:before{
  background: transparent url('../images/sprite_menu.png') -330px 0 no-repeat;
}
.icon-menu-integration:before{
  background: transparent url('../images/sprite_menu.png') -359px 0 no-repeat;
}
.icon-menu-goto-block:before{
  background: transparent url('../images/sprite_menu.png') -360px 0 no-repeat;
}
.icon-menu-refresh:before{
  background: transparent url('../images/sprite_menu.png') -390px 0 no-repeat;
}
.icon-menu-ban-circle:before{
  background: transparent url('../images/sprite_menu.png') -420px 0 no-repeat;
}
.icon-menu-remove:before{
  background: transparent url('../images/sprite_menu.png') -450px 0 no-repeat;
}
.icon-menu-frown:before{
  background: transparent url('../images/sprite_menu.png') -480px 0 no-repeat;
}
.icon-menu-smile:before{
  background: transparent url('../images/sprite_menu.png') -510px 0 no-repeat;
}
.icon-menu-default:before{
  background: transparent url('../images/sprite_menu.png') -567px 0 no-repeat;
}
.icon-menu-exit:before{
  background: transparent url('../images/sprite_menu.png') -180px 0 no-repeat;
}
//при наведении на ссылку с иконкой
.float-menu-item:hover > .icon-menu-users:before,
.float-menu-item:focus > .icon-menu-users:before,
a.icon-menu-users:hover:before,
a.icon-menu-users:focus:before,
a.menu_item_hover.icon-menu-users:before{
  background: transparent url('../images/sprite_menu.png') 0 -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-const:before,
.float-menu-item:focus > .icon-menu-const:before,
a.icon-menu-const:hover:before,
a.icon-menu-const:focus:before,
a.menu_item_hover.icon-menu-const:before{
  background: transparent url('../images/sprite_menu.png') -30px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-nums:before,
.float-menu-item:focus > .icon-menu-nums:before,
a.icon-menu-nums:hover:before,
a.icon-menu-nums:focus:before,
a.menu_item_hover.icon-menu-nums:before{
  background: transparent url('../images/sprite_menu.png') -60px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-fax:before,
.float-menu-item:focus > .icon-menu-fax:before,
a.icon-menu-fax:hover:before,
a.icon-menu-fax:focus:before,
a.menu_item_hover.icon-menu-fax:before{
  background: transparent url('../images/sprite_menu.png') -90px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-stat:before,
.float-menu-item:focus > .icon-menu-stat:before,
a.icon-menu-stat:hover:before,
a.icon-menu-stat:focus:before,
a.menu_item_hover.icon-menu-stat:before{
  background: transparent url('../images/sprite_menu.png') -120px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-lk:before,
.float-menu-item:focus > .icon-menu-lk:before,
a.icon-menu-lk:hover:before,
a.icon-menu-lk:focus:before,
a.menu_item_hover.icon-menu-lk:before{
  background: transparent url('../images/sprite_menu.png') -150px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-volume:before,
.float-menu-item:focus > .icon-menu-volume:before,
a.icon-menu-volume:hover:before,
a.icon-menu-volume:focus:before,
a.menu_item_hover.icon-menu-volume:before{
  background: transparent url('../images/sprite_menu.png') -210px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-key:before,
.float-menu-item:focus > .icon-menu-key:before,
a.icon-menu-key:hover:before,
a.icon-menu-key:focus:before,
a.menu_item_hover.icon-menu-key:before{
  background: transparent url('../images/sprite_menu.png') -270px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-end:before,
.float-menu-item:focus > .icon-menu-end:before,
a.icon-menu-end:hover:before,
a.icon-menu-end:focus:before,
a.menu_item_hover.icon-menu-end:before{
  background: transparent url('../images/sprite_menu.png') -300px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-th:before,
.float-menu-item:focus > .icon-menu-th:before,
a.icon-menu-th:hover:before,
a.icon-menu-th:focus:before,
a.menu_item_hover.icon-menu-th:before{
  background: transparent url('../images/sprite_menu.png') -330px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-goto-block:before,
.float-menu-item:focus > .icon-menu-goto-block:before,
a.icon-menu-goto-block:hover:before,
a.icon-menu-goto-block:focus:before,
a.menu_item_hover.icon-menu-goto-block:before{
  background: transparent url('../images/sprite_menu.png') -360px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-refresh:before,
.float-menu-item:focus > .icon-menu-refresh:before,
a.icon-menu-refresh:hover:before,
a.icon-menu-refresh:focus:before,
a.menu_item_hover.icon-menu-refresh:before{
  background: transparent url('../images/sprite_menu.png') -390px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-ban-circle:before,
.float-menu-item:focus > .icon-menu-ban-circle:before,
a.icon-menu-ban-circle:hover:before,
a.icon-menu-ban-circle:focus:before,
a.menu_item_hover.icon-menu-ban-circle:before{
  background: transparent url('../images/sprite_menu.png') -420px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-remove:before,
.float-menu-item:focus > .icon-menu-remove:before,
a.icon-menu-remove:hover:before,
a.icon-menu-remove:focus:before,
a.menu_item_hover.icon-menu-remove:before{
  background: transparent url('../images/sprite_menu.png') -450px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-frown:before,
.float-menu-item:focus > .icon-menu-frown:before,
a.icon-menu-frown:hover:before,
a.icon-menu-frown:focus:before,
a.menu_item_hover.icon-menu-frown:before{
  background: transparent url('../images/sprite_menu.png') -480px -31px no-repeat;
}
.float-menu-item:hover > .icon-menu-smile:before,
.float-menu-item:focus > .icon-menu-smile:before,
a.icon-menu-smile:hover:before,
a.icon-menu-smile:focus:before,
a.menu_item_hover.icon-menu-smile:before{
  background: transparent url('../images/sprite_menu.png') -510px -31px no-repeat;
}
//активная ссылка с иконкой
a.menu_item_active.icon-menu.icon-menu-users:before{
  background: transparent url('../images/sprite_menu.png') 0 -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-const:before{
  background: transparent url('../images/sprite_menu.png') -30px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-nums:before{
  background: transparent url('../images/sprite_menu.png') -60px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-fax:before{
  background: transparent url('../images/sprite_menu.png') -90px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-stat:before{
  background: transparent url('../images/sprite_menu.png') -120px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-lk:before{
  background: transparent url('../images/sprite_menu.png') -150px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-volume:before{
  background: transparent url('../images/sprite_menu.png') -210px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-key:before{
  background: transparent url('../images/sprite_menu.png') -270px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-end:before{
  background: transparent url('../images/sprite_menu.png') -300px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-th:before{
  background: transparent url('../images/sprite_menu.png') -330px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-goto-block:before{
  background: transparent url('../images/sprite_menu.png') -360px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-refresh:before{
  background: transparent url('../images/sprite_menu.png') -390px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-ban-circle:before{
  background: transparent url('../images/sprite_menu.png') -420px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-remove:before{
  background: transparent url('../images/sprite_menu.png') -450px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-frown:before{
  background: transparent url('../images/sprite_menu.png') -480px -62px no-repeat;
}
a.menu_item_active.icon-menu.icon-menu-smile:before{
  background: transparent url('../images/sprite_menu.png') -510px -62px no-repeat;
}

a.menu_sub_item_active {
  background-color: #e7e7e7 !important;
}

.icon-big_user{
  width: 44px;
  height: 62px;
  background: transparent url('../images/user.png') 0 0 no-repeat;
}

.list-inline {
  white-space: nowrap;
  > li{
    vertical-align: middle;
    float: none;
  }
  &.valign-top{
    > li{
      vertical-align: top !important;
    }
  }
  &.valign-middle{
    > li{
      vertical-align: middle !important;
    }
  }
  &.valign-bottom{
    > li{
      vertical-align: bottom !important;
    }
  }
}

//Левый блок меню
#sidebar{
  //скрытые блоки в развернутом меню
  .sidebar-lg-hide{
    display: none;
  }
  //свернутое меню
  &.sidebar_submenu_active{
    .sidebar-sm-hide{
      display: none;
    }
    .sidebar-sm-show{
      display: block;
    }
    #menu{
      width: @menu-sm-width;
      .menu_item_fade:after{
        content: none;
      }
    }
    #submenu{
      .opacity(1);
      left: @menu-sm-width;
    }
  }
  //верстка пунктов меню/подменю
  .nav{
    > li{
      + li{
        margin: 0;
      }
      > a{
        overflow: hidden;
        white-space: nowrap;
        text-transform: uppercase;
        height: 66px;
        padding: 18px;
        color: @text-color;
        font-family: 'BlissPro-Regular', @font-family-base;
        border-bottom: 2px solid #f6f6f6;
        border-radius: 0;
        position: relative;
        > span{
          display: inline-block;
          vertical-align: middle;
          padding-left: 15px;
        }
        &.menu_item_hover,
        &:hover,
        &:focus{
          outline: none;
          color: @list-group-link-color;
          background: none;
        }
        &.menu_item_active{
          color: #ffffff;
          background: #2678b1;
        }
      }
    }
  }
  #menu{
    width: @menu-lg-width;
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 5;
    > h4{
      padding: 20px 0 0 30px;
      &.logo{
        a{
          color: inherit;
          text-decoration: none;
        }
      }
    }
    &:hover {
      z-index: 10000;
      + #submenu {
        z-index: 9999;
      }
    }
    //стрелка для открытия меню 1 уровня
    > .icon-menu-right{
      padding: 20px 0 33px 20px;
    }
    //блок ФИО клиента
    .user_info{
      overflow: hidden;
      white-space: nowrap;
      padding: 0 0 35px 18px;
      border-bottom: 2px solid #f6f6f6;
      >.btn-logout{
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        background: transparent url('../images/sprite_menu.png') -180px 0 no-repeat;
      }
      >span{
        display: inline-block;
        vertical-align: middle;
        padding-left: 11px;
      }
    }
    //затухание справа, скрывающее длинные названия
    .menu_item_fade:after{
      #gradient.horizontal(transparent; #fff; 0%; 90%);
      content: " ";
      width: 60px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }
    .menu_item_active.menu_item_fade:after{
      #gradient.horizontal(transparent; #2678b1; 0%; 90%);
    }
  }
  #submenu{
    .opacity(0);
    width: @menu-lg-width;
    height: 100%;
    background-color: #e7f4fe;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 4;
    &:hover {
      z-index: 9999;
    }
    .submenu_content{
      display: none;
      &.submenu_content_active{
        display: block;
        left: 60px;
      }
      > h4{
        margin:90px 0 0 0;
        padding: 0 20px;
        height: 61px;
        border-bottom: 2px solid #d4e8f8;
      }
      > .nav{
        > li{
          > a{
            border-bottom: 2px solid #d4e8f8;
            &.submenu_item_active{
              color: #ffffff;
              background: #4e98cc;
            }

          }
        }
      }
    }
    &.active{
      display: block;
    }
  }
}

//переключатель подсказок
.box_easing{
  display: block;
  width: 50px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  .form-control-focus();
  &:hover,
  &:focus{
    text-decoration: none;
    outline: none;
  }
  .box{
    cursor: pointer;
    width: 100%;
    height: 20px;
    padding: 3px 4px 0 3px;
    font-size: 10px;
    border-radius: 10px;
  }
  .label{
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 30px;
    margin: 2px;
  }
  &.on{
    .box{
      background-color: #2678b1;
      color: #ffffff;
    }
    .label{
      background-color: #19bb9a;
    }
  }
  &.off{
    .box{
      background-color: #dfdfdf;
      color: #3b3b3b;
      text-align: right;
    }
    .label{
      background-color: #aeaeae;
      left: 0;
    }
  }
}

//тарифная сетка с вертикальным background при наведении
.tariffs_list{
  position: relative;
  height: 250px;
  white-space: nowrap;
  > .tariffs_list_item{
    display: inline-block;
    vertical-align: top;
    width: 160px;
    height: 250px;
    padding-top: 20px;
    overflow: hidden;
    position: relative;
    > .tariff_item_box{
      border-radius: @border-radius-base;
      padding: 10px;
      position: relative;
      > p{
        height: 50px;
        text-align: right;
        margin-top: 0;
      }
      .tariff_item_mark{
        position: absolute;
        top: -25px;
        right: 0;
      }
      &:hover{
        background-color: @table-bg-hover;
      }
    }
    &:hover{
      height: auto;
    }
    &:first-child{
      width: 385px;
      padding-left: 0;
      > .tariff_item_box{
        > p{
          text-align: left;
        }
      }
      > .tariff_item_box:hover{
        background-color: transparent;
      }
    }
  }
  &.tariffs_list_4{
    > .tariffs_list_item{
      width: 150px;
      &:first-child{
        width: 250px;
      }
    }
  }
}

// input со стрелками
.inputIncrement{
  display: inline-block;
  .inputIncrement__leftArr,
  .inputIncrement__rightArr,
  .inputIncrement__input{
    color: @text-color;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
  }
  .inputIncrement__input{
    width: 47px;
    text-align: center;
    font-size: @font-size-large;
  }
}

//модальное окно
.modal{
  .modal-title{
    font-family:'BlissPro-Regular', @font-family-base;
    color: #2d2d2d;
  }
  .modal-body{
    position: relative;
    z-index: 1;
  }
  .modal-footer{
    text-align: left;
    .btn_w225{
      width: 225px;
    }
  }
  //кнопка закрыть
  .close {
    .opacity(1);
    position: relative;
    z-index: 2;
    margin: 6px 6px 0 0;
    &:hover,
    &:focus {
      .opacity(1);
    }
  }
}

.modal-alert, .modal-confirm {
  .modal-footer {
    text-align: center;
  }
}

//accordion - сворачивающиеся блоки
a[data-toggle="collapse"]{
  display: block;
  position: relative;
  color: @text-color;
  //иконка
  &:before{
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent url('../images/sprite_icons.png') -20px 0 no-repeat;
  }
  &.h3{
    font-family:'BlissPro-Regular', @font-family-base;
    text-transform: uppercase;
    margin: 0;
  }
  &:hover,
  &:focus{
    text-decoration: none;
    outline: none;
  }
  &:hover,
  &:focus{
    color: @list-group-link-color;
    //иконка синего цвета
    &:before{
      background: transparent url('../images/sprite_icons.png') -20px -20px no-repeat;
    }
  }
  &.collapsed{
    &:before{
      background: transparent url('../images/sprite_icons.png') -40px 0 no-repeat;
    }
    &:hover,
    &:focus{
      //иконка синего цвета
      &:before{
        background: transparent url('../images/sprite_icons.png') -40px -20px no-repeat;
      }
    }
  }
  & + .collapse{
    padding-top: 18px;
  }
}

// Элементы формы
// -------------------------

label {
   font-weight: normal;
}
.form-group {
  margin-bottom: 25px;
}
.form-control{
  .box-shadow(none);
  border-width: 2px;
  &.input-short{
    width: 50px;
  }
}
.checkbox > label,
.radio > label,
.checkbox-inline,
.radio-inline{
  > span{
    display: inline-block;
    vertical-align: middle;
    color: @list-group-link-color;
    border-bottom: 1px dotted @list-group-link-color;
    margin-left: 8px;
  }
}
.checkbox,
.radio{
  label{
    padding-left: 0;
  }
  > label.active{
    > span{
      color: @text-color;
      border: 0;
    }
  }
}
.form-group-textarea{
  &{
    border: 2px solid @input-border;
    border-radius: @input-border-radius;
  }
  textarea{
    border: 0;
    resize: none;
  }
}

// Кастомные checkbox и radio с плагином icheck
// -------------------------

//Mixin оформление checkbox и radio
.icheck(@url-sprite: '../images/icheck.png'){
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(@url-sprite) no-repeat;
  border: none;
  cursor: pointer;
}
//Mixin background-position checkbox
.icheck_checkbox_position(){
  background-position: 0 0;
  &.hover {
    background-position: -24px 0;
  }
  &.checked {
    background-position: -48px 0;
  }
  &.disabled {
    background-position: -72px 0;
    cursor: default;
  }
  &.checked.disabled {
    background-position: -96px 0;
  }
}
//Mixin background-position radio
.icheck_radio_position(){
  background-position: -120px 0;
  &.hover {
    background-position: -144px 0;
  }
  &.checked {
    background-position: -168px 0;
  }
  &.disabled {
    background-position: -192px 0;
    cursor: default;
  }
  &.checked.disabled {
    background-position: -216px 0;
  }
}

//Серые checkbox
.icheckbox_square{
  .icheck();
  .icheck_checkbox_position();
}
//Серые radio
.iradio_square{
  .icheck();
  .icheck_radio_position();
}

//Белые checkbox
.icheckbox_square-white{
  .icheck('../images/icheck_white.png');
  .icheck_checkbox_position();
}
//Белые radio
.iradio_square-white{
  .icheck('../images/icheck_white.png');
  .icheck_radio_position();
}


// Кастомный input[file]
// -------------------------

.input_file_wrap{
  overflow:hidden;
  width:100%;
  height:22px;
  .input_file_title {
    cursor: pointer;
    color: @list-group-link-color;
    border-bottom: 1px dotted @list-group-link-color;
  }
  input[type="file"]{
    height: 22px;
    width: 130px;
    font-size: 150px;
    margin-top:-22px;
    .opacity(0);
    cursor: pointer;
  }
}

// Кастомный select
// -------------------------

//ширина select
@select_width: 100%;
//ширина стрелки
@icon-width: 20px;
//отступ иконки от правого края
@icon-right-width: 10px;

select{
  width: @select_width;
  &.select_w180{
    width: 180px;
  }
}
.ui-datepicker{
  z-index: 1061 !important;
}
.ui-timepicker-div{
  padding: 10px;
  dl{
    dt{
      padding: 5px 0;
    }
  }
}
.ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font: 400 @font-size-base/@line-height-base @font-family-base;
}
//для select внутри модального окна
.ui-front{
  z-index: 1100;
}

// Элемент selectmenu
// -------------------------

//Mixin оформление кнопки
.select_btn(){
  .text-overflow();
  display: block;
  height: @input-height-base;
  color: @input-color;
  background-image: none;
  border: 2px solid @input-border;
  border-radius: @input-border-radius;
  outline: none;
  position: relative;
  text-align: left;
  padding-right: @icon-width + @icon-right-width;
  //стиль при фокусе на select
  //затухание справа на текст
  &:after{
    #gradient.horizontal(transparent; @input-bg; 0%; 80%);
    position: absolute;
    content: " ";
    width: 50px;
    height: 100%;
    top: 0;
    right: @icon-width + @icon-right-width;
    z-index: 3;
  }
}
//Mixin оформление иконки
.select_btn_icon(){
  position: absolute;
  top: 50%;
  left: auto;
  right: @icon-right-width;
  width: @icon-width;
  height: 20px;
  background: transparent url('../images/sprite_icons.png') -40px 0 no-repeat;
  right: @icon-right-width;
  margin-top: -10px;
  z-index: 2;
}
.select_active_btn_icon(){
  background: transparent url('../images/sprite_icons.png') -20px 0 no-repeat;
}
//Mixin оформление текста внутри кнопки
.select_btn_text(){
  padding: 0;
  font-size: @font-size-base;
  line-height: 1.7;
  padding: @padding-base-vertical @padding-base-horizontal;
  position: relative;
  z-index: 1;
}
//Mixin стиль кнопки в развернутом варианте
.select_btn_active(){
  border-radius: @input-border-radius @input-border-radius 0 0;
  background-color: @input-bg;
  border-color: @input-border;
  .box-shadow(none);
}

//Кнопка selectmenu
.ui-selectmenu-button{
  .select_btn();
  span.ui-icon{
    .select_btn_icon();
  }
  span.ui-selectmenu-text{
    .select_btn_text();
  }
  //стиль кнопки в развернутом варианте
  &.ui-corner-top{
    .select_btn_active();
    span.ui-icon{
      .select_active_btn_icon()
    }
  }
}
//Меню с вариантами selectmenu
.ui-selectmenu-menu{
  .ui-menu{
    border: 2px solid @input-border;
    border-top: 0;
    .ui-menu-item{
      border: 0;
      line-height: 25px;
      padding-left: 12px;
      &:hover,
      &.ui-state-focus,
      &.ui-state-active{
        margin: 0;
        background:none;
        background-color: @table-bg-hover;
      }
    }
  }
}

// Элемент Multiselect
// -------------------------

//Кнопка multiselect
.ui-multiselect{
  max-width: 100%;
  .select_btn();
  span.ui-icon{
    .select_btn_icon();
  }
  span:last-child{
    .select_btn_text();
  }
  //стиль кнопки в развернутом варианте
  &.ui-state-active{
    .select_btn_active();
    border-bottom: 0 !important;
    span.ui-icon{
      .select_active_btn_icon()
    }
  }
}
//Меню с вариантами Multiselect
.ui-multiselect-menu{
  display:none;
  position:absolute;
  border: 2px solid @input-border;
  border-top: 0;
  border-radius: 0 0 @border-radius-base @border-radius-base;
  z-index:10000;
  .ui-widget-header{
    background:none;
    border: 0;
    .ui-multiselect-filter{
      padding: 0px 15px 5px 12px;
      position: relative;
      .icon{
        position: absolute;
        top: 5px;
        right: 25px;
      }
      input{
        width: 100%;
        display: block;
        width: 100%;
        height: 32px;
        padding: @padding-base-vertical 25px @padding-base-vertical @padding-small-horizontal;
        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @input-color;
        background-color: @input-bg;
        border: 2px solid @input-border;
        border-radius: @input-border-radius;
        .form-control-focus();
      }
    }
    .ui-helper-reset{
      display: none;
    }
  }
  .ui-multiselect-checkboxes{
    position:relative;
    overflow-y:scroll;
    margin: 5px 0;
    > li {
      > label{
        display: block;
        background:none;
        border: 0;
        line-height: 25px;
        padding: 2px 2px 4px 12px;
        &:hover,
        &.ui-state-focus,
        &.ui-state-active{
          background:none;
          background-color: @table-bg-hover;
          border-radius: 0px;
        }
        > input{
          position:relative;
        }
        > span{
          padding-left: 5px;
        }
      }
    }
  }
}

//Фон selectmenu и Multiselect
// -------------------------

//по умолчанию прозрачный select
.ui-multiselect,
.ui-selectmenu-button{
  background-color:transparent;
  border-color: transparent;
  margin-left: -@padding-base-horizontal;
  .form-control-focus();
  //затухание длинного текста выбранного значения
  &:after{
    #gradient.horizontal(transparent; @body-bg; 0%; 80%);
  }
  //стиль кнопки в развернутом варианте
  &.ui-corner-top,
  &.ui-state-active{
    .select_btn_active();
    &:after{
      #gradient.horizontal(transparent; @input-bg; 0%; 80%);
    }
  }
}
//цвет затухания текста в кнопке selectmenu в таблице table-hover
.table-hover{
  tr:hover{
    .ui-multiselect,
    .ui-selectmenu-button{
      &:after{
        #gradient.horizontal(transparent; @table-bg-hover; 0%; 80%);
      }
      //стиль кнопки в развернутом варианте
      &.ui-corner-top,
      &.ui-state-active{
        .select_btn_active();
        &:after{
          #gradient.horizontal(transparent; @input-bg; 0%; 80%);
        }
      }
    }
  }
}
//внутри well фон select белый с серой рамкой
.well,
.modal{
  .ui-multiselect,
  .ui-selectmenu-button{
    background-color:@input-bg;
    border-color: @input-border;
    margin-left: 0;
    .form-control-focus();
    //затухание длинного текста выбранного значения
    &:after{
      #gradient.horizontal(transparent; @input-bg; 0%; 80%);
    }
    //стиль кнопки в развернутом варианте
    &.ui-corner-top,
    &.ui-state-active{
      .select_btn_active();
    }
  }
  //цвет затухания текста в кнопке selectmenu в таблице table-hover
  .table-hover{
    tr:hover{
      .ui-multiselect,
      .ui-selectmenu-button{
        &:after{
          #gradient.horizontal(transparent; @input-bg; 0%; 80%);
        }
      }
    }
  }
}
.has-success {
  .ui-multiselect,
  .ui-selectmenu-button{
    border-color: @brand-success;
    margin-left: 0;
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@brand-success, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@brand-success, 20%);
      .box-shadow(@brand-success);
    }
  }
}
.has-warning {
  .ui-multiselect,
  .ui-selectmenu-button{
    border-color: @brand-warning;
    margin-left: 0;
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@brand-warning, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@brand-warning, 20%);
      .box-shadow(@brand-warning);
    }
  }
}
.has-error {
  .ui-multiselect,
  .ui-selectmenu-button{
    border-color: @brand-danger;
    margin-left: 0;
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@brand-danger, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@brand-danger, 20%);
      .box-shadow(@brand-danger);
    }
  }
}



//Дополнительные alerts
.alert-warning-bright {
  .alert-variant(@state-warning-bright-bg; @state-warning-bright-border; @state-warning-bright-text);
}

//Кнопки
*[class^="btn"] {
  border-width: 2px;
  border-radius: @border-radius-base;
}
.btn{
  text-transform: uppercase;
  font-size: 13px;
  font-family:'BlissPro-Regular', @font-family-base;
  padding: 10px 20px 8px 20px;
}
.btn-lg{
  padding: 12px 50px;
}
.btn-sm{
  padding: 10px;
}
.btn-xs{
  padding: 5px;
}
.btn-primary{
  .box-shadow(0 2px 1px @btn-primary-box-shadow-color);
}
.btn-info{
  .box-shadow(0 2px 1px @btn-info-box-shadow-color);
}
.btn-warning{
  .box-shadow(0 2px 1px @btn-warning-box-shadow-color);
}
.btn-success{
  .box-shadow(0 2px 1px @btn-success-box-shadow-color);
  letter-spacing: 1px;
}
.btn-danger{
  .box-shadow(0 2px 1px @btn-danger-box-shadow-color);
}
//Группа кнопок с белым фоном и цветной рамкой
.btn[class$="light"] {
  font-size: 14px;
  font-family:'BlissPro-Extrabold', @font-family-base;
  border-radius: @border-radius-small;
  padding: 10px 20px;
}
.btn-primary-light {
  .button-variant(@btn-primary-border; transparent; @btn-primary-border);
}
.btn-success-light {
  .button-variant(@btn-success-border; transparent; @btn-success-border);
}
.btn-info-light {
  .button-variant(@btn-info-border; transparent; @btn-info-border);
}
.btn-warning-light {
  .button-variant(@btn-warning-border; transparent; @btn-warning-border);
}
.btn-danger-light {
  .button-variant(@btn-danger-border; transparent; @btn-danger-border);
}

//фиксированная ширина блоков
.block_w155{
  width: 155px;
}
.block_w245{
  width: 245px;
}
//блок с затуханием в конце с фоном body
.block_body_fade{
  .text-overflow();
  position: relative;
  &:after{
    #gradient.horizontal(transparent; @body-bg; 0%; 90%);
    content: " ";
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
  }
}
.well{
  .block_body_fade{
    &:after{
      #gradient.horizontal(transparent; @well-bg; 0%; 90%);
    }
  }
}
//Цвет затухания в table-hover
.table-hover{
  tr:hover{
    .block_body_fade{
      &:after{
        #gradient.horizontal(transparent; @table-bg-hover; 0%; 90%);
      }
    }
  }
}

//цветные точки для статусов
.point{
  display: inline-block;
  vertical-align: middle;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-right: 10px;
  + span{
    display: inline-block;
    vertical-align: middle;
  }
}
.point-success{
  background-color: @brand-success;
}
.point-info{
  background-color: @brand-info;
}
.point-warning{
  background-color: @brand-warning;
}
.point-danger{
  background-color: @brand-danger;
}

//Кнопка Закрыть
.close{
  margin-right: 4px;
  font-weight: normal;
  .opacity(1);
  text-shadow:none;
  &:hover{
    .opacity(1);
  }
}

//Всплывающий подсказки
.popover_help{
  background-color: @state-warning-bright-bg;
  border-color: @state-warning-bright-bg;
  &.right {
    max-width: none;
    > .arrow {
      border-right-color: @state-warning-bright-bg; // IE8 fallback
      border-right-color: @state-warning-bright-bg;
      &:after {
        border-right-color: @state-warning-bright-bg;
      }
    }
  }
  &.bottom > .arrow {
    border-bottom-color: @state-warning-bright-bg; // IE8 fallback
    border-bottom-color: @state-warning-bright-bg;
    &:after {
      border-bottom-color: @state-warning-bright-bg;
    }
  }
  &.top > .arrow {
    border-top-color: @state-warning-bright-bg; // IE8 fallback
    border-top-color: @state-warning-bright-bg;
    &:after {
      border-top-color: @state-warning-bright-bg;
    }
  }
}

body .popover {
  font-size: 12px;
}

// Tabs
// -------------------------
.nav-tabs {
  > li {
    > a {
      font-size: 18px;
      line-height: 20px;
      > span{
        border-bottom: 1px dotted @list-group-link-color;
      }
    }
    &.active{
      > a{
        > span{
          border-bottom: 0;
        }
      }
    }
  }
}
.tab-content{
  background-color: #ffffff;
  border-radius: 0 @border-radius-base @border-radius-base @border-radius-base;
  padding: 20px 15px;
  .search_phones_result{
    border-top: 2px dotted #dfdfdf;
    margin-top: 20px;
    padding-top: 20px;
    .checkbox-inline{
      padding: 0;
      margin-bottom: 20px;
    }
  }
}

//список сценариев
.list-group-draggable{
  .list-group-draggable-item{
    border-radius: @border-radius-base;
    background-color: @list-group-draggable-item;
    padding: @list-group-draggable-item-padding;
    margin: @list-group-draggable-item-margin;
    z-index: 2;
    &:hover,
    &:focus{
      cursor: move;
      background-color: @list-group-draggable-item-hover;
    }
    .glyphicon{
      font-size: 18px;
      line-height: 19px;
    }
    a:hover,
    a:focus{
      text-decoration: none;
    }
    &.ui-draggable-dragging{
      cursor: move;
      position: relative;
      &:before{
        content: " ";
        position: absolute;
        top: 50%;
        left: 10px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background: transparent url('../images/sprite_icons.png') 0px -20px no-repeat;
      }
      > div:first-child{
        text-indent: -9999px;
      }
    }
  }
}

.nav-success {
  > li {
    // Links rendered as pills
    > a {
      border-radius: @border-radius-small;
      background-color: #e9e9e9;
      border:2px solid #e9e9e9;
      color: @btn-success-color;
      padding: 4px 8px;
      &:hover,
      &:focus{
        background-color: #e9e9e9;
        color: @btn-success-bg;
        border-color: @btn-success-bg;
      }
    }
    + li {
      margin-left: 31px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @btn-success-color;
        background-color: @btn-success-bg;
        border-color: @btn-success-bg;
      }
    }
  }
}

//Верстка блок-схемы
.ivr-script{
  position: relative;
  width: 100%;
  text-align: center;
  padding: 10px;
  z-index: 0;
  min-height: 800px;

  .leaf-block{
    &{
      display: inline-block;
      vertical-align: top;
      position: relative;
      z-index: 0;
    }
    .brunch,
    .brunch-bottom,
    .brunch-left,
    .brunch-right{
      position: absolute;
      left: 50%;
      top: 30px;
      background: #d4d4d4;
      width: 2px;
      height: 35px;
      margin-left: -1px;
      z-index: 1;
    }
    .brunch-bottom{
      height: 30px;
      top: auto;
      bottom: -30px;
      margin-left: -1px;
    }
    .brunch-left{
      width: 50%;
      height: 2px
    }
    .brunch-right {
      left: 0;
      width: 50%;
      height: 2px
    }
  }

  .leaf{
    &{
      position: relative;
      padding: 20px 50px;
      margin: 65px 10px 0 10px;
      min-width: 100px;
      min-height: 30px;
      background: #ffffff;
      border-radius: @border-radius-base;
      display: inline-block
    }
    > span {
      cursor: default;
      font-size: 14px;
      font-family:'BlissPro-Light', @font-family-base;
      text-transform: uppercase;
    }

    &.leaf-type-start,
    &.leaf-type-exit{
      border-radius: 30px;
      padding: 15px;
      width: 200px;
    }
    &.leaf-type-start{
      background-color: @btn-primary-bg;
      color: @btn-primary-color;
    }

    .glyphicon-remove-circle,
    .ivr-dialog .glyphicon-remove-circle {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -10px;
      font-size: 20px;
      line-height: 20px;
      cursor: pointer;
    }

    .leaf-link{
      &{
        position: absolute;
        top: -13px;
        left: 50%;
        width: 24px;
        height: 22px;
        margin-top: -15px;
        cursor: pointer;
        text-align: left;
        margin-left: -12px;
        //background-size: 524px !important;
        z-index: 2;
        background-color: #efefef !important;
      }
      &.link-type-default_link{
        background: transparent url('../images/sprite_menu_524.png') -499px -2px no-repeat;
      }
      &.link-type-success{
        background: transparent url('../images/sprite_menu_524.png') -449px -2px no-repeat;
      }
      &.link-type-fail{
        background: url('../images/sprite_menu_524.png') -423px -2px no-repeat;
      }
      &.link-type-noanswer{
        background: transparent url('../images/sprite_menu_524.png') -396px -2px no-repeat;
      }
      &.link-type-busy{
        background: transparent url('../images/sprite_menu_524.png') -370px -2px no-repeat;
      }
      &.link-type-digit_link{
        background: transparent url('../images/sprite_menu_524.png') -237px -2px no-repeat;
      }
      span {
        font-size: 16px;
        line-height: 20px;
        font-family: 'BlissPro-Extrabold', 'Roboto', Arial, sans-serif;
        padding-left: 6px;
      }
    }
    .leaf-icon{
      position: absolute;
      top: 16px;
      left: 14px;
      width: 30px;
      height: 31px;
    }
    &.leaf-type-menu .leaf-icon{
      background: transparent url('../images/sprite_menu.png') -331px 0 no-repeat;
    }
    &.leaf-type-play_prompts .leaf-icon{
      background: transparent url('../images/sprite_menu.png') -210px 0 no-repeat;
    }
    &.leaf-type-call_transfer_system .leaf-icon{
      background: transparent url('../images/sprite_menu.png') -390px 0 no-repeat;
    }
    &.leaf-type-goto_block .leaf-icon{
      background: transparent url('../images/sprite_menu.png') -360px 0 no-repeat;
    }
    &.leaf-type-jump .leaf-icon{
      background: transparent url('../images/sprite_menu.png') -30px 0 no-repeat;
    }
    &.leaf-type-exit .leaf-icon{
      top: 10px;
      background: transparent url('../images/sprite_menu.png') -300px 0 no-repeat;
    }

    .add-after,
    .add-before{
      display: block;
      cursor: pointer;
      position: absolute;
      left: 50%;
      width: 30px;
      height: 30px;
      background: transparent url('../images/sprite_menu.png') -540px 0 no-repeat;
      margin-left: -15px;
      z-index: 2;
      &.disabled{
        background: transparent url('../images/sprite_menu.png') -540px -31px no-repeat;
      }
    }
    .add-after{
      bottom: -28px;
    }
    .add-before{
      top: -15px;
      display: none;
    }
  }

  .level{
    &{
      white-space: nowrap;
    }
    > .leaf-block{
      &:first-of-type > .brunch-right{
        display: none
      }
      &:last-of-type > .brunch-left{
        display: none
      }
    }
  }

}
.ivr-file-frame {
  position: absolute;
  top: 0;
  left: -5000px;
  width: 10px;
  height: 10px
}
.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.float-menu {
  &{
    position: absolute;
    background: #e7f4fe;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 2;
  }
  > li {
    padding: 18px 15px;
    cursor: pointer;
    white-space: nowrap;
    color: @text-color;
    border-bottom: 2px solid #d0e6f7;
    font-family: 'BlissPro-Regular', @font-family-base;
    text-transform: uppercase;
    position: relative;
    &:hover,
    &:focus {
      outline: none;
      color: @list-group-link-color;
      background: none;
    }
    > .icon-menu{
      padding-right: 15px;
      display: inline-block;
      vertical-align: middle;
    }
    .icon-menu-key-symbol{
      position: absolute;
      top:18px;
      left: 15px;
      font-size: 16px;
      line-height: 25px;
      font-family: 'BlissPro-Extrabold', 'Roboto', Arial, sans-serif;
      padding-left: 7px;
    }
  }
  &.float-menu-main{
    &{
      background: #fff;
    }
    &:before{
      content: " ";
      display: block;
      position: absolute;
      top:-12px;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 12px 10px;
      border-color: transparent transparent #ffffff transparent;
      z-index: 2;
    }
    > li {
      border-bottom: 2px solid #f6f6f6;
    }
  }
}

//Верстка легенды для графика на статистике
.legend{
  .legend-color,
  .legend-title{
    display: inline-block;
    vertical-align: middle;
  }
  .legend-color{
    width: 20px;
    height: 20px;
  }
  .legend-title{
    padding-left: 8px;
  }
}


.main-page-container .tariffs_list {
  margin-bottom: 60px;
}

.alert-remembered {
  visibility: hidden;
}

.sortable-table thead th {
  &{
    cursor: pointer;
  }
  &.order-asc:after {
    content: "\e155";
    font-family: 'Glyphicons Halflings';
    vertical-align: middle;
  }
  &.order-desc:after {
    content: "\e156";
    font-family: 'Glyphicons Halflings';
    vertical-align: middle;
  }
}

.show-pass-icon {
  .glyphicon;
  position: absolute;
  cursor: pointer;
  padding: 0 5px;
}

.no-max-width {
  max-width: none;
}

// Верстка меню на мобильниках
@media (max-width: 1200px) {
  #container:not(.login) {
    margin: 0 !important;
    padding: 60px !important;
    width: @wrapper-width !important;
    .logo{
      color: #3b3b3b;
    }
    #sidebar_top{
      .navbar-nav .open .dropdown-menu{
        position: absolute;
      }
      .nav{
        > li{
          float: left;
          > a{
            height: auto;
            padding: 10px 22px;
          }
        }
      }
      #sidebar_top_navbar{
        .dropdown-menu{
          background-color: #F8F8F8;
          border-color: #E7E7E7;
          > li{
            > a{
              font-family: 'BlissPro-Regular', @font-family-base;
              font-size: 16px;
              color: #3b3b3b;
            }
          }
        }
        .btn-logout {
          width: 30px;
          height: 30px;
          margin: 10px 22px;
          padding: 0;
          background: transparent url('../images/sprite_menu.png') -180px 0 no-repeat;
        }
      }
    }
  }
}


.brand-bg         { background-color: @brand-primary }
.brand-bg-success { background-color: @brand-success }
.brand-bg-info    { background-color: @brand-info }
.brand-bg-warning { background-color: @brand-warning }
.brand-bg-danger  { background-color: @brand-danger }

.brand-color         { color: @brand-primary }
.brand-color-success { color: @brand-success }
.brand-color-info    { color: @brand-info }
.brand-color-warning { color: @brand-warning }
.brand-color-danger  { color: @brand-danger }


.storage-info .storage-clean-btn {
  display: none;
  margin-left: 15px
}
.storage-info:hover .storage-clean-btn {
  display: inline;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom-dot {
  border-bottom:1px dotted #ccc;
}

.row-45 {
  height: 45px;
}

.table-cell-45 {
  display: table-cell;
  line-height: 45px;
}

.float-left {
  float: left;
}

.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input { background: none; color: inherit; border: none; outline: none; border-bottom: solid 1px #555; width: 95%; }
.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input:focus { border-bottom-color: #aaa; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; }

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine { padding-right: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time,
.ui-timepicker-div.ui-timepicker-oneLine dt { display: none; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label { display: block; padding-top: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine dl { text-align: right; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd,
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div { display:inline-block; margin:0; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before { content:':'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before { content:'.'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before{ display: none; }

.red_color {
  color:#FF0000;
  cursor:pointer;
}

.green_color {
  color:#008000;
  cursor:pointer;
}

.reg_status {
  width:10px;
}


.information_call.in_call_success {
  color: #4e98cc;
}